import * as React from "react"
import Layout from '../components/Layout'
import SEO from "../components/seo"
import {AllFaqs} from "../components/AllFaqs"

const FaqsPage = () => {

  return (
    <Layout clsOverflow={false} typeRouteMenu={'/#'}>
      <SEO
        title=""
        description=""
        works=''
        bolImage='1'
        ogImage={`home_f.jpg`}
        twitterImage={`home_t.jpg`}
        canonicalUrl="https://www.callmatik.com/faqs"
      />

      <AllFaqs/>

    </Layout>
  )
}

export default FaqsPage
